import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        sanityContact {
          title
          subtitle
        }
      }
    `}
    render={data => (
      <div id="contact-form" className="contactForm">
        <p className="title ">{data.sanityContact.title}</p>
        <p className="subtitle ">{data.sanityContact.subtitle}</p>

        <form
          className="contact_form"
          name="contact-form"
          method="POST"
          action="/mail-sendt"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          enctype="multipart/form-data"
        >
          {/* <input type="hidden" name="bot-field" /> */}
          <input type="hidden" name="form-name" value="contact-form" />

          <div className="upper_wrapper">
            <div className="input_wrapper">
              <input type="text" name="navn" id="navn" required />
              <label htmlFor="navn">Navn</label>
            </div>
            <div className="input_wrapper">
              <input type="text" name="email" id="email" required />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input_wrapper">
              <input type="text" name="virksomhed" id="virksomhed" required />
              <label htmlFor="virksomhed">Virksomhed</label>
            </div>
          </div>

          <div className="input_wrapper message ">
            <textarea name="message" id="message" rows="6" required />
            <label htmlFor="message">Besked</label>
          </div>
          <div>
            <input
              className="sendButton "
              type="submit"
              value="Send"
              // onClick={defineAction}
            />
          </div>
        </form>
      </div>
    )}
  />
)

// function defineAction() {
//   let getURL = window.location.href

//   let shortenURL = getURL.replace("https://zignifikant.dk", "")

//   document.querySelector(".contact_form").action = shortenURL + "/mail-sendt"
// }
